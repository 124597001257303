<template>
  <div>
      <LiefengContent>
        <template v-slot:title>设备管理</template>
        <template v-slot:toolsbarRight>
              <Button type="primary" @click="openAddModal" icon="ios-add"  style="margin-right: 10px">新增</Button>
        </template>
        <!--身体部分-->
        <template v-slot:contentArea>
             <div class="container">
                 <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[50, 200, 500, 1000]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
             </div>
        </template>
      </LiefengContent>
     <!--修改设备-->
      <LiefengModal
        :value="selectModal"
        title="修改设备"
        :fullscreen="false"
        @input="selectStatus"
        height="100%"
      >
        <template v-slot:contentarea>
            <Form label-position="left" :label-width="100">
                <FormItem>
                      <span slot="label" class="validate">设备名称:</span>
                       <Input
                        :maxlength="20"
                        v-model.trim="editRow.name"
                        placeholder="请输入设备名称"
                        />
                </FormItem>   
                 <FormItem>
                     <span slot="label" class="validate">设备类型:</span>
                      <Select
                        v-model="editRow.brandId"
                        placeholder="请选择设备类型"
                        filterable
                        @on-change="selectEditBrand"
                    >
                        <Option
                        v-for="(item, index) in brandList"
                        :key="index"
                        :value="item.id"
                        >{{ item.name }}</Option
                        >
                    </Select>
                </FormItem>   
                <FormItem>
                     <span slot="label" class="validate">设备状态:</span>
                      <Select
                        v-model="editRow.status"
                        placeholder="请选择设备状态"
                        filterable
                        @on-change="selectEditStatusChange"
                    >
                        <Option
                        v-for="(item, index) in statusList"
                        :key="index"
                        :value="item.value"
                        >{{ item.lable }}</Option
                        >
                    </Select>
                </FormItem>   
            </Form>
        </template>
        <template v-slot:toolsbar>
           <Button style="margin: 0 5px 0 13px" type="warning" @click="selectTip=true;selectStatus(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="saveImplement">保存</Button>
        </template>
      </LiefengModal>
      <LiefengModal 
        :value="addModal.status"
        title="新增设备"
        :fullscreen="false"
        @input="addModalFn"
        height="100%">
        <template v-slot:contentarea>
            <Form label-position="left" :label-width="100">
                <FormItem>
                      <span slot="label" class="validate">设备名称:</span>
                       <Input
                        :maxlength="20"
                        v-model.trim="addRow.name"
                        placeholder="请输入设备名称"
                        />
                </FormItem>
                <FormItem>
                     <span slot="label" class="validate">设备类型:</span>
                      <Select
                        v-model="addRow.brandId"
                        placeholder="请选择设备类型"
                        filterable
                        @on-change="selectBrand"
                    >
                        <Option
                        v-for="(item, index) in brandList"
                        :key="index"
                        :value="item.id"
                        >{{ item.name }}</Option
                        >
                    </Select>
                </FormItem>   
                <FormItem>
                     <span slot="label" class="validate">设备状态:</span>
                      <Select
                        v-model="addRow.status"
                        placeholder="请选择设备状态"
                        filterable
                        @on-change="selectStatusChange"
                    >
                        <Option
                        v-for="(item, index) in statusList"
                        :key="index"
                        :value="item.value"
                        >{{ item.lable }}</Option
                        >
                    </Select>
                </FormItem>   
            </Form>
        </template>
        <template v-slot:toolsbar>
           <Button style="margin: 0 5px 0 13px" type="warning" @click="addModal.tip=true;addModalFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="addSave">保存</Button>
        </template>
      </LiefengModal>
  </div>
</template>

<script>
  //@route("/equipmentindex")
  import LiefengContent from "@/components/LiefengContent";
  import LiefengTable from "@/components/LiefengTable";
  import LiefengModal from "@/components/LiefengModal";
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {LiefengContent,LiefengTable,LiefengModal},
    data() {
      //这里存放数据
      return {
          talbeColumns:[
              {
                title: "名称",
                key: "name",
                minWidth: 200,
                align: "center"
            },
            {
                title: "状态",
                key: "status",
                minWidth: 100,
                align: "center",
                render:(h,params) =>{
                    return h('div',{
                        style:{
                            textAlign: "center",
                        }
                    },params.row.status == 1 ?'库存' : params.row.activateStatus == 2 ?'上线' :params.row.activateStatus == 3 ?'报废':'')
                }
            },
            {
                title: "是否绑定",
                key: "isBanding",
                minWidth: 100,
                align: "center",
                render:(h,params) =>{
                    return h('div',{
                        style:{
                            textAlign: "center",
                        }
                    },params.row.isBanding == 1 ?'是' : params.row.isBanding == 2?'否' :'')
                }
            },
            {
                title: "运行状态",
                key: "runStatus",
                minWidth: 100,
                align: "center",
                render:(h,params) =>{
                    return h('div',{
                        style:{
                            textAlign: "center",
                        }
                    },params.row.runStatus == 0 ?'正常' : params.row.runStatus == 1?'异常' :'')
                }
            },
            {
                title: "设备控制指令",
                key: "cmd",
                minWidth: 200,
                align: "center"
            },
            {
                title: "购买时间",
                key: "buyTime",
                minWidth: 200,
                align: "center"
            },
            {
                title: "设备图标",
                minWidth: 100,
                align: "center",
                 render: (h, params) => {
                           return h(
                             "div",
                             {
                               style: {
                                 textAlign: "center",
                                 padding: "10px 0",
                                 display: "inline-block",
                                 marginRight: "10px",
                                 cursor: "pointer"
                               }
                             },
                             [
                               h(
                                 "a",
                                 {
                                   attrs: {
                                     target: "view_window",
                                     href: params.row.imageUrl
                                   }
                                 },
                                 [
                                   h("img", {
                                     attrs: {
                                       src: params.row.imageUrl
                                     },
                                     style: {
                                       width: "40px",
                                       height: "40px",
                                       display: params.row.imageUrl ? 'block' :'none'
                                     }
                                   })
                                 ]
                               )
                             ]
                           );
                       
                 }
            },
            {
                title:'操作',
                align:'center',
                minWidth:100,
                render:(h,params) =>{
                     return h(
                        "div",
                        {
                            style: {
                            textAlign: "left",
                            display:'flex',
                            justifyContent:'center'
                            },
                        },
                        [
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.editRow.name = params.row.name
                                        this.editRow.id = params.row.id
                                        if(this.brandList && this.brandList.length != 0){
                                            this.brandList.map(item =>{
                                                if(item.deviceType == params.row.deviceType){
                                                    this.editRow.brandId = item.id
                                                }
                                            })
                                        }else{
                                            this.editRow.brandId = ''
                                        } 
                                        this.editRow.status = params.row.status
                                        this.selectModal = true;
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "修改" //1001
                            ),       
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "error",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                       this.$Modal.confirm({
                                           title: "温馨提示",
                                            content: "<p>确认要删除该数据吗？</p>",
                                            onOk: () => {
                                                this.deleteRow(params.row.deviceId)
                                            },
                                       })
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "删除" //1001
                            ),       
                         ]
                     );
                }
            }
          ],
          tableData:[],
          loading:false,
          page:1,
          total:0,
          pageSize:20,
          selectModal:false,
          selectTip:false,
          editRow:{name:'',brandId:'',status:''},
          addRow:{
              name:'',
              brandId:'',
              status:''
          },
          addModal:{
              status:false,
              tip:false
          },
          brandList:[],
          statusList:[
              {lable:'库存',value:'1'},
              {lable:'上线 ',value:'2'},
              {lable:'报废',value:'3'},
          ],
          token:''
      }
    },
    //方法集合
    methods: {
        selectEditBrand(val){
            this.editRow.brandId = val
        },
        selectEditStatusChange(val){
            this.editRow.status = val
        },
        selectStatusChange(val){
            this.addRow.status =val
        },
        selectBrand(val){
            this.addRow.brandId = val
        },
        // 点击新增的按钮事件
        openAddModal(){
             this.addModal.status = true;
        },
        // 新增的模态框
        addModalFn(status){
            // if(!status && this.addModal.tip){
            //     this.$Modal.confirm({
            //         title: "温馨提示",
            //         content: "您正在关闭此窗口，信息将不会保存，是否继续",
            //         onOk: () => {
            //             console.log('如果点了ok',this.addModal.status,status);                       
            //             this.addModal.status = false;
                        this.addModal.tip = false
                        this.addRow = {
                             name:'',
                             brandId:'',
                             status:''
                        }
            //         }
            //      });
            // }else{
                  this.addModal.status = status;
            // }
        },
        // 新增模态框的保存按钮
        addSave(){
            if(this.addRow.name == ''){
                this.$Message.warning({
                    content:'设备名称不能为空',
                    background:true
                })
                return
            } 
            if(this.addRow.brandId == ''){
                 this.$Message.warning({
                    content:'请选择设备类型',
                    background:true
                })
                return
            }
            let data = {
                name:this.addRow.name,
                brandId:this.addRow.brandId,
                status:this.addRow.status
            }
            this.addEquipmemt(data)
        },
        // 修改的保存按钮
        saveImplement(){
            if(this.editRow.name == ''){
                this.$Message.warning({
                    content:'设备名称不能为空',
                    background:true
                })
                return
            }else if(this.editRow.brandId == ''){
                 this.$Message.warning({
                    content:'请选择设备类型',
                    background:true
                })
                return
            }else if(this.editRow.status == ''){
                 this.$Message.warning({
                    content:'请选择设备状态',
                    background:true
                })
                return
            }
                let data = {
                deviceName:this.editRow.name,
                deviceId:this.editRow.id,
                brandId:this.editRow.brandId,
                status:this.editRow.status
               } 
                this.updateRow(data)
           
        },
        //选择接收人弹窗状态改变
        selectStatus(status) {
            // if(!status && this.selectTip){
            //     this.$Modal.confirm({
            //         title: "温馨提示",
            //         content: "您正在关闭此窗口，信息将不会保存，是否继续",
            //         onOk: () => {
            //             this.selectModal = status;
            //             this.selectTip = false
            //         }
            //      });
            // }else{
                 this.selectModal = status;
            // }
           
        },
        hadlePageSize(val){
            this.page = val.page,
            this.pageSize = val.pageSize
            this.getList()
        },

        // 接口部分
        getList(){
            this.$get('/gateway/api/homecare/pc/Device/selectDevices',{
                currPage:this.page,
                pageSize:this.pageSize,
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                // bindTime:'',
                // deviceGlobalId:'',
                // deviceName:'',
                // deviceType:'',
                // parentDeviceGlobalId:'',
                // parentDeviceId:'',
                // runStatus:'',
                // status:''
            },{
                Authorization:this.token 
            }).then(res=>{
                if(res.code == 200){
                    this.tableData  = res.data.dataList
                }else{
                    this.$Message.error({
                        content:res.msg,
                        background:true
                    })
                    return
                }
                
            })
        },
        // 删除接口
        deleteRow(data){
            this.$post('/gateway/api/homecare/pc/Device/deleteDevice',{
                deviceId:data,
            },{
                Authorization:this.token 
            }).then(res=>{
                if(res && res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    this.getList()
                }else{
                    this.$Message.error({
                        content:data.desc,
                        background:true
                    })
                }
            })
        },
        // 修改设备名称接口
        updateRow(data){
            this.$post('/gateway/api/homecare/pc/Device/updateDevice',{
                deviceId:data.deviceId,
                deviceName:data.deviceName,
                brandId:data.brandId,
                oemCode:parent.vue.oemInfo.oemCode,
                status:data.status
            },{
                Authorization:this.token 
            }).then(res=>{
                if(res && res.code == 200){
                    this.$Message.success({
                        content:'修改成功',
                        background:true
                    })
                    this.selectModal = false
                    this.editRow = {}
                    this.selectTip = false
                    this.getList()
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        // 新增接口
        addEquipmemt(data){
            this.$post('/gateway/api/homecare/pc/Device/addDevice',{
                brandId:data.brandId,
                // deviceId:'',
                deviceName:data.name,
                oemCode:parent.vue.oemInfo.oemCode,
                status:data.status
            },{
                Authorization:this.token 
            }).then(res=>{
                if(res && res.code == 200){
                    this.$Message.success({
                        content:'新增成功',
                        background:true
                    })
                     this.addModal.status = false;
                     this.addModal.tip = false
                     this.addRow = {
                             name:'',
                             brandId:'',
                             status:''
                    }
                     this.getList()
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        // 获取设备类型接口
        getBrand(){
            this.$get('/gateway/api/homecare/pc/Device/selectDevicesBrand','',{
                Authorization:this.token ,"Accept": ""
            }
            ).then(res=>{
                if(res.code == 200){
                    this.brandList = res.dataList
                }else{
                    this.$Message.error({
                        content:res.msg,
                        background:true
                    })
                    return
                }
            })
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
            this.token = 'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIxNzMyODY4MDAyNCIsInNjb3BlIjpbImFsbCJdLCJleHAiOjE2MjQzOTcyMDEsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJqdGkiOiJkN2QwODMzNy05MjhiLTQxZjUtYTFjMC0zNDY3NThmYTVkYzEiLCJjbGllbnRfaWQiOiJzdWlhbmp1IiwidG9rZW4iOiJzdWlhbmp1bG9naW5fdG9rZW5fZmUxOTQyNDI1ZGNiNGJmMDg5ZTk3NmRmN2Q4ZGIwMzUifQ.kSyATZVIu17Ggtc8NXh0NeZR03nNlFYshsDWPeUhAfLWIr6zheDxtGycpBrNZvBPI1Fm6pdl-uSUJr11gj8vVvDHvU1xICDi5_9QwQ8gz7beSC_LIBfjfhWIxTV4BXo8b70NyX_8hb8m-EKH0TjTRRnggHQTsku7opIg5uaY9Y0'
        let data = {
            deviceType:'',
            keyword:''
        }
        this.getList(data)
        this.getBrand()
    
    },
  }
</script>
<style lang='less' scoped>
  //编写css
   .detail-container{
          .title{
              display: flex;
              justify-content: flex-start;
              width: 100%;
              margin: 10px 0;
              font-size: 16px;
              .left{
                  width: 15%;
                  
              }
              .right{
                  width: 85%;
              }
          }
    }
    .equiment-title{
        position: relative;
        padding-left: 20px;
        margin:10px 0;
        &::after{
            position: absolute;
            content:'';
            top: 0;
            left: 0;
            width: 5px;
            height: 20px;
            background-color: #2db7f5;
        }
    }
    /deep/ #modal_contentarea{
        overflow: visible !important;
    }
</style>